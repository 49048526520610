<template>
  <div class="card border-primary">
    <h5 class="card-header border-primary bg-primary text-white">
      รายการชนิดหวย
    </h5>
    <div class="card-body">
      <router-link
        class="btn btn-success text-white mb-2"
        :to="{ name: 'LottoTypeAdd' }"
      >
        เพิ่มชนิดหวย
      </router-link>
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-dark text-center">
          <thead>
            <tr>
              <th>#ID</th>
              <th>ประเภท</th>
              <th>ชื่อ</th>
              <th>เวลาออกผล</th>
              <th>วันเปิด</th>
              <th>เวลาสร้าง</th>
              <th>แก้ไขล่าสุด</th>
              <th>แก้ไข</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lottoType, index) in lottoTypes" :key="index">
              <td>{{ lottoType.id }}</td>
              <td>{{ lottoType.categoryName }}</td>
              <td>{{ lottoType.name }}</td>
              <td>
                {{ $date(lottoType.closeTime, "HH:mm:ss").format("HH:mm") }}
              </td>
              <td>{{ lottoType.openDayTH }}</td>
              <td>
                {{ $date(lottoType.createdAt).format("DD/MM/YYYY HH:mm:ss") }}
              </td>
              <td>
                {{ $date(lottoType.updatedAt).format("DD/MM/YYYY HH:mm:ss") }}
              </td>
              <td>
                <router-link
                  class="btn btn-primary"
                  :to="{
                    name: 'LottoTypeEdit',
                    params: {
                      id: lottoType.id,
                    },
                  }"
                  >แก้ไข</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lottoTypes: [],
    };
  },
  methods: {},
  async mounted() {
    this.swal.processing();
    try {
      let categoryPromise = this.axios({
        method: "get",
        url: "lotto/category",
      });
      let typePromise = this.axios({
        method: "get",
        url: "lotto/type",
      });

      let [categoryRes, typeRes] = await Promise.all([
        categoryPromise,
        typePromise,
      ]);

      typeRes.data.forEach((item) => {
        item.openDayTH = "";
        if (item.monday) {
          item.openDayTH += "จันทร์,";
        }
        if (item.tuesday) {
          item.openDayTH += "อังคาร,";
        }
        if (item.wednesday) {
          item.openDayTH += "พุธ,";
        }
        if (item.thursday) {
          item.openDayTH += "พฤหัสบดี,";
        }
        if (item.friday) {
          item.openDayTH += "ศุกร์,";
        }
        if (item.saturday) {
          item.openDayTH += "เสาร์,";
        }
        if (item.sunday) {
          item.openDayTH += "อาทิตย์,";
        }
        item.openDayTH = item.openDayTH.slice(0, -1);

        let cat = categoryRes.data.find(
          (item2) => item2.id == item.lottoCategoryId
        );
        item.categoryName = cat.name;
      });

      this.lottoTypes = typeRes.data;

      this.swal.close();
    } catch (e) {
      console.log(e);
      this.swal.swalError();
    }
  },
};
</script>